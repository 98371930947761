import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
//import { HashRouter, Route, Switch } from "react-router-dom";

import Login from "./components/Login";
import Error404 from "./components/error404";
import Welcome from "./components/Welcome";

//Reg ususrios
import FirstStep from './views/PasosReg/FirstStep';
//import Header from './components/Pasos_Reg/Header';
import SecondStep from './views/PasosReg/SecondStep';
import ThirdStep from './views/PasosReg/ThirdStep';

//Recupera ususrios
import FirstStepRec from './views/PasosRec/FirstStepRec';
import SecondStepRec from './views/PasosRec/SecondStepRec';
import ThirdStepRec from './views/PasosRec/ThirdStepRec';

import Swal from 'sweetalert2';

import "./scss/style.scss";




const App = () => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();


  const updateUser = (data) => {
    setUser((prevUser) => ({ ...prevUser, ...data }));
  };

  const resetUser = () => {
    setUser({});
  };

  const [isMounted, setIsMounted] = useState(true);
  useEffect(() => {
    if (isMounted) {
      history.listen((location) => {
        dispatch(clearMessage()); // clear message when changing location
      });
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    }
  }, []);

  const logOut = () => {
    dispatch(logout());
  };

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  // Containers
  const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

  return (
    <React.Suspense fallback={loading}>
      <Router>
        <Switch>
          <Route
            exact={true}
            path="/"
            render={(props) => <Login {...props} user={user} updateUser={updateUser} />}
          />

          <Route
            path="/ingresodenuncia"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/dashboard"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/dashboardtipo"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/dashboardum"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/dashboardumtipo"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/miscasosactivos"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/mapacasosactivos"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/mapascasosactivosderivados"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/profile"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/ingresoparte"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/mispartes"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/inspecciondashboardtipo"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/busquedainfraccionall"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/misasignaciones"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/personalenturno"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/sindespachar"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/enejecucion"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/busquedaimagenes"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/busquedagrabaciones"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/busquedasms"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/busquedacall"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/trackUserGeo"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/mapaUserGeo"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/listadollamadosia"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/trackSeguridadGeo"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/trackMasGeo"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/planintervencion"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/busquedaall"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/administrador"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/dashboardadmin"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            path="/AnalisisTactico"
            render={(props) => <DefaultLayout {...props} />}
          />

          <Route
            render={(props) => (
              <FirstStep {...props} user={user} updateUser={updateUser} />
            )}
            path="/registro"
            exact={true}
          />

          <Route
            render={(props) => (
              <SecondStep {...props} user={user} updateUser={updateUser} />
            )}
            path="/registro/second"
          />

          <Route
            render={(props) => (
              <ThirdStep
                {...props}
                user={user}
                updateUser={updateUser}
                resetUser={resetUser}
              />
            )}
            path="/registro/third"
          />

          <Route
            render={(props) => (
              <FirstStepRec {...props} user={user} updateUser={updateUser} />
            )}
            path="/recupera"
            exact={true}
          />

          <Route
            render={(props) => (
              <SecondStepRec {...props} user={user} updateUser={updateUser} />
            )}
            path="/recupera/second"
          />
          <Route
            render={(props) => (
              <ThirdStepRec
                {...props}
                user={user}
                updateUser={updateUser}
                resetUser={resetUser}
              />
            )}
            path="/recupera/third"
          />

          <Route
            path="/confirm/:confirmationCode"
            component={Welcome}
          />

          <Route
            path="*"
            render={(props) => <Login {...props} user={user} updateUser={updateUser} />}
          />

        </Switch>
      </Router>
    </React.Suspense >
  )
};

export default App;